<template>
  <div id="app">
    <div class="light-blue">
      <nav class="navbar navbar-expand-md bg-body-tertiary ng-box-shadow-light">
        <div class="container container-fluid">
          <a class="navbar-brand" href="/">
            <img src="@/assets/logo-horizontal-main.png" height="60" alt="" />
          </a>

          <div id="navbarNav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a href="#contact">
                  <button
                    class="btn btn-outline-primary fw-bold text-nowrap hide-on-mobile"
                  >
                    Get a Free Consultation
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="light-blue pb-3">
      <div class="row mb-3">
        <div class="col-md-12 p-5 text-center introduction-text">
          <div class="mt-5">
            <h1 class="highlight-blue fw-700">
              <span
                ><typewriter
                  class="typewriter"
                  :replace="replace"
                  :type-interval="60"
                  :replace-interval="2000"
                >
                  <span>Big enough to deliver,</span>
                </typewriter></span
              >
            </h1>
            <h2>
              We <b>Craft</b> Websites & Cross-Platform Mobile Apps. <br />
              We <b>Integrate</b> & <b>Automate</b> business processes.
            </h2>
          </div>
        </div>
      </div>
      <div class="row mb-4 brands-slider">
        <vue-marquee-slider
          id="brand-slider-1"
          class="brands"
          :speed="50000"
          :width="150"
          :space="100"
        >
          <img src="@/assets/images/brands/glico.png" alt="" />
          <img src="@/assets/images/brands/beauty_cosmetics.png" alt="" />
          <img src="@/assets/images/brands/evergrowth.png" alt="" />
          <!-- <img src="@/assets/images/brands/zeepay.png" /> -->
          <img src="@/assets/images/brands/newlucky.png" alt="" />
          <img src="@/assets/images/brands/markapp.png" alt="" />
          <img src="@/assets/images/brands/ezipay.png" alt="" />
          <img src="@/assets/images/brands/selby_properties.png" alt="" />
        </vue-marquee-slider>
      </div>
      <div class="row mt-4 mb-5 brands-slider">
        <vue-marquee-slider
          id="brand-slider-2"
          class="brands"
          :speed="50000"
          :width="150"
          :space="100"
          :reverse="true"
        >
          <img src="@/assets/images/brands/kalami.png" alt="" />
          <img src="@/assets/images/brands/radiowiz.png" alt="" />
          <img src="@/assets/images/brands/workmorr.png" alt="" />
          <img src="@/assets/images/brands/united_nations.png" alt="" />
          <img src="@/assets/images/brands/the_jeans_shop_gh.png" alt="" />
          <img src="@/assets/images/brands/sp_pay.png" alt="" />
          <img src="@/assets/images/brands/sykes_and_partners.png" alt="" />
          <img src="@/assets/images/brands/realty_market.png" alt="" />
        </vue-marquee-slider>
      </div>
    </div>
    <div class="row pt-3 pb-0 light-orange">
      <h1 class="mt-4">Our <span class="highlight-orange">Process</span>.</h1>
      <div class="row justify-content-center mb-5">
        <div class="col-lg-7 pl-5">
          <div class="row mt-2 container">
            <div class="col-md-4 mt-4 process-item">
              <h2 class="fw-bold process-heading">
                <span class="highlight-orange">1.</span> Intake
              </h2>
              <p>We meet with you to discuss your needs and how we can help.</p>
            </div>
            <div class="col-md-4 mt-4 process-item">
              <h2 class="fw-bold process-heading">
                <span class="highlight-orange">2.</span> Planning
              </h2>
              <p>
                We go to the drawing board and present a plan for your project.
              </p>
            </div>
            <div class="col-md-4 mt-4 process-item">
              <h2 class="fw-bold process-heading">
                <span class="highlight-orange">3.</span> Execution
              </h2>
              <p>
                Once approved, we will develop your project with speed and
                efficiency.
              </p>
            </div>
            <div class="col-md-4 mt-4 process-item">
              <h2 class="fw-bold process-heading">
                <span class="highlight-orange">4.</span> Deployment & Review
              </h2>
              <p>We deploy your project, and monitor it's performance.</p>
            </div>
            <div class="col-md-4 mt-4 process-item">
              <h2 class="fw-bold process-heading">
                <span class="highlight-orange">5.</span> Completed
              </h2>
              <p>
                Project is handed over to your team and signed off accordingly.
              </p>
            </div>
            <div class="col-md-4 mt-4 process-item">
              <h2 class="fw-bold process-heading">
                <span class="highlight-orange">6.</span> Maintenance
              </h2>
              <p>We stay with you, and assist you with issues and upgrades.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5 pb-5 light-green" id="contact">
      <h1>Let's Get to <span class="highlight-green">Work</span>.</h1>
      <div class="row justify-content-center mt-3">
        <div class="col-lg-5 ng-box-shadow-light text-start p-4 mb-5">
          <div v-if="success" class="alert alert-success" role="alert">
            {{ success }}
          </div>
          <div v-if="errors.length > 0" class="alert alert-danger" role="alert">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>
          <form>
            <h3 class="text-start mt-3">About You</h3>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="first-name" class="form-label"
                    >First name *</label
                  >
                  <input
                    v-model="form.first_name"
                    :disabled="this.loading"
                    type="text"
                    class="form-control"
                    id="first-name"
                    placeholder="Your first name."
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="last-name" class="form-label required"
                    >Last name *</label
                  >
                  <input
                    v-model="form.last_name"
                    :disabled="this.loading"
                    type="text"
                    class="form-control"
                    id="last-name"
                    placeholder="Your last name."
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="business-email" class="form-label"
                    >Business Email *</label
                  >
                  <input
                    v-model="form.business_email"
                    :disabled="this.loading"
                    type="text"
                    class="form-control"
                    id="business-email"
                    placeholder="Your business email."
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="phone" class="form-label required">Phone</label>
                  <input
                    v-model="form.phone"
                    :disabled="this.loading"
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder="Your phone, optional."
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="channel" class="form-label"
                    >How did you hear about us? *</label
                  >
                  <input
                    v-model="form.discovery"
                    :disabled="this.loading"
                    type="text"
                    class="form-control"
                    id="channel"
                    placeholder="Referral, online or through advertisment ?"
                  />
                </div>
              </div>
            </div>
            <h3 class="text-start mt-3 mb-2">About Project</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="goal" class="form-label"
                    >What do you want to achieve? *</label
                  >
                  <select
                    v-model="form.goal_id"
                    :disabled="this.loading"
                    class="form-select"
                    aria-label=""
                    id="goal"
                  >
                    <option value="1">
                      Grow business by building a new digital product.
                    </option>
                    <option value="2">
                      Digital partner to complement my internal team.
                    </option>
                    <option value="3">Something else.</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">What is your budget? *</label>
                  <div class="">
                    <input
                      v-model="form.budget_id"
                      :disabled="this.loading"
                      type="radio"
                      class="btn-check"
                      id="up-to-5000"
                      value="1"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-2 btn-outline-success btn-sm fw-bold"
                      for="up-to-5000"
                      >Up to ₵5,000</label
                    >
                    &nbsp;
                    <input
                      v-model="form.budget_id"
                      :disabled="this.loading"
                      type="radio"
                      class="btn-check"
                      id="5000-to-10000"
                      value="2"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-2 btn-outline-success btn-sm fw-bold"
                      for="5000-to-10000"
                      >₵5,000 to ₵10,000</label
                    >
                    &nbsp;
                    <input
                      v-model="form.budget_id"
                      :disabled="this.loading"
                      type="radio"
                      class="btn-check"
                      id="10000-to-20000"
                      value="3"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-2 btn-outline-success btn-sm fw-bold"
                      for="10000-to-20000"
                      >₵10,000 to ₵20,000</label
                    >
                    &nbsp;
                    <input
                      v-model="form.budget_id"
                      :disabled="this.loading"
                      type="radio"
                      class="btn-check"
                      id="20000-to-50000"
                      value="4"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-2 btn-outline-success btn-sm fw-bold"
                      for="20000-to-50000"
                    >
                      ₵20,000 to ₵50,000</label
                    >
                    &nbsp;
                    <input
                      v-model="form.budget_id"
                      :disabled="this.loading"
                      type="radio"
                      class="btn-check"
                      id="over-50000"
                      value="5"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-2 btn-outline-success btn-sm fw-bold"
                      for="over-50000"
                      >Over ₵50,000</label
                    >
                    &nbsp;
                    <input
                      v-model="form.budget_id"
                      :disabled="this.loading"
                      type="radio"
                      class="btn-check"
                      id="cant-disclose"
                      value="6"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-2 btn-outline-success btn-sm fw-bold"
                      for="cant-disclose"
                      >Can't disclose.</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="project" class="form-label"
                    >Tell us about your project. *</label
                  >
                  <textarea
                    :disabled="this.loading"
                    v-model="form.details"
                    rows="4"
                    type="text"
                    class="form-control"
                    id="project"
                    placeholder="How would you like us to help ?"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="text-end mr-2">
              <button
                @click.prevent="submit"
                class="btn btn-success btn-md"
                :disabled="this.loading"
                type="submit"
                variant="primary"
              >
                {{
                  loading == true
                    ? "Loading, please wait..."
                    : "Submit details."
                }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer class="">
      <div class="container">
        <div class="row">
          <div class="col-md-6 fw-bold mt-2" style="font-size: 12px">
            © Nusoft Group
            {{ new Date().getFullYear() != "2024" ? "2024 - " : "" }}
            {{ new Date().getFullYear() }}
            <span class="highlight-blue">|</span> PLT 161 Odumse, Dodowa -
            Accra, Ghana.
          </div>
          <div class="col-md-6" style="font-size: 12px">
            Log in to Our
            <a href="https://clients.nusoftgroup.com"
              ><b class="highlight-blue">Client Portal</b></a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Typewriter from "typewriter-vue";
import LeadService from "./services/LeadService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "App",
  components: {
    Typewriter,
  },
  data() {
    return {
      loading: false,
      replace: [
        { from: "Big enough to deliver", to: "Small enough to care." },
        { from: "Small enough to care", to: "Your Trusted Partner." },
      ],
      form: {
        first_name: "",
        last_name: "",
        business_email: "",
        phone: "",
        discovery: "",
        goal_id: "1",
        budget_id: "",
        details: "",
      },
      success: "",
      errors: [],
    };
  },
  mounted() {
    document.title = `Nusoft Group - Innovative & Scalable Business Solutions.`;
  },
  methods: {
    submit() {
      this.loading = true;
      LeadService.requestACallback(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.form.first_name = "";
          this.form.last_name = "";
          this.form.business_email = "";
          this.form.phone = "";
          this.form.discovery = "";
          this.form.goal_id = "1";
          this.form.budget_id = "";
          this.form.details = "";

          // Track event
          this.$gtag.event("manual_event_SUBMIT_LEAD_FORM");
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
@import "./assets/styles/main.css";
</style>

