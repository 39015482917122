import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import VueMarqueeSlider from 'vue-marquee-slider';
Vue.use(VueMarqueeSlider)

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-LML3DQRBJ5" }
});

new Vue({
  render: h => h(App),
}).$mount('#app')
